import { useMemo } from 'react'

const get = (obj, path) => {
    const pathArray = path?.split('.')

    return pathArray?.reduce(
        (result, key) => (result && typeof result === 'object' && key in result ? result[key] : null),
        obj
    )
}

export const useVariantStyles = (theme, variant, defaultStyles) =>
    useMemo(() => ({ ...defaultStyles, ...get(theme.layoutTheme.variants, variant) }), [theme, variant, defaultStyles])
