import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Link } from '../../../Link'
import styles from './Label.style'

const HeadingLabelStyled = styled.div(props => ({ ...styles(props) }))

function HeadingLabel({ href, hrefAs, title, children, className, lineBefore, lineAfter, ...rest }) {
    if (!children) {
        return null
    }

    return (
        <HeadingLabelStyled className={className} lineAfter={lineAfter} lineBefore={lineBefore} {...rest}>
            <Link href={href} as={hrefAs} title={title}>
                <span className="HeadingLabel_inner">{children}</span>
            </Link>
        </HeadingLabelStyled>
    )
}

HeadingLabel.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    title: PropTypes.string,
    lineBefore: PropTypes.bool,
    lineAfter: PropTypes.bool
}

HeadingLabel.defaultProps = {
    className: undefined,
    href: '/',
    hrefAs: '/',
    title: undefined,
    lineBefore: true,
    lineAfter: true
}

export default withErrorBoundary(HeadingLabel, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[HeadingLabel]: ', error, componentStack)
    }
})
