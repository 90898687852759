// @TODO: Open/close transition animations
import { toRems } from '../../helpers/theme'
/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, open }) => ({
    padding: toRems([0, 10]),
    display: 'none',
    position: 'fixed',
    zIndex: theme.zIndex.modal,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    pointerEvents: 'none',
    ...(open && {
        display: 'block'
    }),
    '& .modalInner': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        outline: 0,
        opacity: 0,
        pointerEvents: 'none',
        transitionDuration: theme.transitions.modal.default.duration,
        transitionTimingFunction: theme.transitions.modal.default.timingFunction,
        transitionDelay: theme.transitions.modal.default.delay,
        transitionProperty: 'opacity',
        ...(open && {
            opacity: 1
        })
    }
})

export default style
