import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import clsx from 'clsx'

import { useVariantStyles } from '../../hooks/layout/useVariantStyles'

const columnDefaultStyles = {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0
}

export const ElementStyled = styled('div')(props => props.styles)
ElementStyled.displayName = 'ColumnStyled'

function Column({ children, theme, className, ...rest }) {
    const cssStyles = useVariantStyles(theme, rest.variant, columnDefaultStyles)

    if (!children) {
        return null
    }

    const classNameParsed = clsx(rest.variant?.replace('.', '__').replaceAll('.', '-'), className, 'is_column')
    return (
        <ElementStyled {...rest} styles={cssStyles} className={classNameParsed}>
            {children}
        </ElementStyled>
    )
}

export default withTheme(Column)
