// @TODO: Open/close transition animations (variables are set and ready)

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useModal } from '@hmn/rtl-web-core/hooks'

import { Backdrop, Dialog } from './components'
import styles from './Modal.style'

const ModalStyled = styled.div(props => ({ ...styles(props) }))

function Modal({ uid, children, disableCloseButton, disableBackdrop, disableBackdropClose, className, ...rest }) {
    const { isOpen, close } = useModal(uid)

    return (
        <ModalStyled open={isOpen} className={className} role="presentation" {...rest}>
            <Backdrop
                disabled={disableBackdrop}
                onClick={close}
                disableOnClick={disableBackdropClose}
                className="modalBackdrop"
            />
            <div className="modalInner" role="none presentation">
                <Dialog active={isOpen} onCloseClick={close} disableCloseButton={disableCloseButton}>
                    {children}
                </Dialog>
            </div>
        </ModalStyled>
    )
}

Modal.propTypes = {
    uid: PropTypes.string.isRequired,
    className: PropTypes.string,
    disableCloseButton: PropTypes.bool,
    disableBackdropClose: PropTypes.bool,
    disableBackdrop: PropTypes.bool
}

Modal.defaultProps = {
    className: undefined,
    disableCloseButton: false,
    disableBackdropClose: false,
    disableBackdrop: false
}

export default withTheme(Modal)
