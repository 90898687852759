// @TODO: Open/close transition animations

import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    maxHeight: '100%',
    minHeight: toRem(40),
    padding: toRems([50]),
    margin: 0,
    position: 'relative',
    overflowY: 'auto',
    backgroundColor: theme.colors.modal.background.default.content,
    pointerEvents: 'auto',
    boxShadow: theme.shadows.default,
    [theme.breakpoints.up('md')]: {
        maxWidth: toRem(600)
    },
    '& .dialogClose': {
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'block',
        [theme.breakpoints.up('md')]: {
            right: toRem(20),
            top: toRem(20)
        },
        button: {
            border: 'none',
            background: 'transparent'
        }
    },
    '& .dialogContent': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        padding: 0,
        margin: 0
    }
})

export default style
