import { useEffect } from 'react'

import { useCurrentEntityTypeDispatch } from '../../context/clientNavigation'
import { EntityType } from '../../types/entity'

const useEntityTypeDispatch = (type: EntityType) => {
    const setEntityType = useCurrentEntityTypeDispatch()
    useEffect(() => {
        if (type) setEntityType(type)
        return () => {
            setEntityType('loading')
        }
    }, [type, setEntityType])
}

export default useEntityTypeDispatch
