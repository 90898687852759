// @TODO: Open/close transition animations (variables are ready)

import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Button } from '../../../Button'
import { CloseIcon, Icon } from '../../../Icon'
import styles from './Dialog.style'

const DialogStyled = styled.div(props => ({ ...styles(props) }))

function Dialog({ active, children, onCloseClick, disableCloseButton, className, ...rest }) {
    const dialogRef = useRef()

    const handleTabKey = useCallback(e => {
        const focusableModalElements = dialogRef.current.querySelectorAll(
            'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
        )
        const numElements = focusableModalElements?.length
        const firstElement = focusableModalElements[0]
        const lastElement = focusableModalElements[numElements - 1]

        if (numElements) {
            if (!e.shiftKey && (document.activeElement !== firstElement || numElements === 1)) {
                firstElement.focus()
                e.preventDefault()
            }

            if (e.shiftKey && document.activeElement !== lastElement) {
                lastElement.focus()
                e.preventDefault()
            }
        }
    }, [])

    const keyListenersMap = useMemo(
        () =>
            new Map([
                [27, onCloseClick],
                [9, handleTabKey]
            ]),
        [handleTabKey, onCloseClick]
    )

    useEffect(() => {
        const keyListener = e => {
            const listener = keyListenersMap.get(e.keyCode)
            return listener && listener(e)
        }
        if (active) {
            document.addEventListener('keydown', keyListener)
        }
        return () => {
            if (active) {
                document.removeEventListener('keydown', keyListener)
            }
        }
    }, [active, keyListenersMap])

    return (
        <DialogStyled className={className} role="dialog" {...rest} ref={dialogRef}>
            {!disableCloseButton && (
                <div className="dialogClose">
                    <Button isIconOnly simple title="Zatvori" onClick={onCloseClick}>
                        <span className="showForSr">Zatvori</span>
                        <Icon icon={CloseIcon} style={{ fontSize: 50 }} />
                    </Button>
                </div>
            )}
            <div className="dialogContent">{children}</div>
        </DialogStyled>
    )
}

Dialog.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onCloseClick: PropTypes.func,
    disableCloseButton: PropTypes.bool
}

Dialog.defaultProps = {
    active: false,
    className: undefined,
    onCloseClick: () => {},
    disableCloseButton: false
}

export default withTheme(
    withErrorBoundary(Dialog, {
        FallbackComponent: () => null,
        onError(error, componentStack) {
            // eslint-disable-next-line no-console
            console.error('[ModalDialog]: ', error, componentStack)
        }
    })
)
