import { toRem } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    '& .profilePopup_image': {
        maxWidth: toRem(70),
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            maxWidth: toRem(100)
        },
        // @TODO: Remove this below, this has to be solved in the image component - when image is loaded remove placeholder (logo) so we dont have a problem with transparent images
        img: {
            background: theme.colors.background.body.default
        }
    },
    '& .profilePopup_title': {
        textAlign: 'center',
        paddingTop: toRem(30),
        marginBottom: toRem(20),
        color: theme.colors.heading.basic.text.default,
        fontSize: toRem(20),
        fontFamily: theme.typography.fontFamilyAlt,
        fontStyle: theme.typography.font.style.italic,
        fontWeight: theme.typography.fontWeight.medium,
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(24)
        }
    },
    '& .profilePopup_content': {
        textAlign: 'center',
        paddingBottom: toRem(35),
        fontSize: toRem(16),
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(18)
        }
    },
    '& .profilePopup_form': {
        display: 'flex',
        flexDirection: 'column'
    },
    '& .profilePopup_buttons': {
        marginTop: toRem(40),
        marginBottom: toRem(40)
    },
    '& .profilePopup_textField': {
        marginBottom: toRem(20)
    }
})

export default style
