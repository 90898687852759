import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Backdrop.style'

const BackdropStyled = styled.div(props => ({ ...styles(props) }))

function Backdrop({ disabled, onClick, disableOnClick, className, ...rest }) {
    if (disabled) {
        return null
    }

    const handleClick = () => {
        if (!disableOnClick) {
            onClick()
        }
    }

    return <BackdropStyled className={className} onClick={handleClick} aria-hidden="true" {...rest} />
}

Backdrop.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    disableOnClick: PropTypes.bool,
    className: PropTypes.string
}

Backdrop.defaultProps = {
    disabled: false,
    onClick: () => {},
    disableOnClick: false,
    className: undefined
}

export default withTheme(Backdrop)
