import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, lineBefore, lineAfter }) => {
    const letterSpacing = toRem(6)
    const lineHeight = 1.2
    const fontSize = toRem(12)

    return {
        '& .HeadingLabel_inner': {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            fontSize,
            letterSpacing,
            lineHeight,
            fontWeight: theme.typography.fontWeight.bold,
            textTransform: 'uppercase',
            color: theme.colors.heading.label.text.default,
            ...(lineBefore && {
                '&::before': {
                    content: '""',
                    margin: toRems([0, 10]),
                    width: toRem(24),
                    height: 1,
                    background: theme.colors.heading.label.border.default
                }
            }),
            ...(lineAfter && {
                '&::after': {
                    content: '""',
                    margin: toRems([0, 10]),
                    width: toRem(24),
                    height: 1,
                    background: theme.colors.heading.label.border.default
                }
            })
        }
    }
}

export default style
