import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { MenuItem, TextField } from '@mui/material'
import { subYears } from 'date-fns'
import dayjs from 'dayjs'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { useModal } from '@hmn/rtl-web-core/hooks'

import useUser from '../../hooks/useUser'
import { Button, buttonVariants } from '../Button'
import { DatePickerField } from '../DatePicker'
import { ButtonsRow } from '../GlobalProfile/components'
import { Image } from '../Image'
import { Modal } from '../Modal'
import styles from './ProfilePopup.style'

const formDefaultValues = {
    first_name: '',
    last_name: '',
    birthday: null,
    sex: '',
    city: ''
}

const ProfilePopupStyled = styled.div(props => ({ ...styles(props) }))

function ProfilePopup({ profileModalUid, className, ...rest }) {
    const { profile, update, isLoggedIn, logIn } = useUser()
    const attributesSelected = useMemo(() => profile?.extended_attributes || {}, [profile])
    const { toggle: toggleModal } = useModal(profileModalUid)

    const initialValues = useMemo(() => {
        const values = Object.keys(formDefaultValues).reduce((all, key) => {
            all[key] = attributesSelected[key] ? attributesSelected[key] : formDefaultValues[key]
            return all
        }, {})
        return {
            ...values,
            birthday: values.birthday ? dayjs(values.birthday).format('YYYY-MM-DD') : null
        }
    }, [attributesSelected])

    // Form submit
    const handleSubmit = useCallback(
        values => {
            const requests = []
            if (Object.keys(values).some(key => values[key] !== attributesSelected[key])) {
                requests.push(
                    update({
                        extended_attributes: {
                            ...Object.keys(values).reduce((all, key) => {
                                all[key] = values[key] || null
                                return all
                            }, {}),
                            birthday: values.birthday ? dayjs(values.birthday).format() : null
                        }
                    })
                )
            }

            Promise.allSettled(requests).then(() => {
                // done
            })
            toggleModal()
        },
        [update, attributesSelected, toggleModal]
    )

    if (!profileModalUid) {
        return null
    }
    return (
        <Modal uid={profileModalUid}>
            <ProfilePopupStyled className={className} {...rest}>
                {!isLoggedIn ? (
                    <div className="profilePopup_inner">
                        <h2 className="profilePopup_title">Hej, prijavi se!</h2>
                        <p className="profilePopup_content">
                            Ako želiš komentirati, ocjenjivati i sudjelovati u nagradnim igrama, registriraj se ili
                            prijavi sa svojim računom.
                        </p>
                        <ButtonsRow className="profilePopup_buttons">
                            <Button
                                onClick={() => logIn()}
                                type="button"
                                title="Prijavi se"
                                variant={buttonVariants.BETA}>
                                Prijavi se
                            </Button>
                        </ButtonsRow>
                    </div>
                ) : (
                    <div className="profilePopup_inner">
                        <div className="profilePopup_image">
                            <Image alt="Profile avatar" image="/images/profile/avatar.svg" />
                        </div>
                        <h2 className="profilePopup_title">Hej, popuni svoj profil!</h2>
                        <p className="profilePopup_content">
                            Ako želiš komentirati, ocjenjivati i sudjelovati u nagradnim igrama, popuni obvezene podatke
                            na svojem profilu. Ovo moramo tražiti od tebe, ali bez brige –{' '}
                            <strong>ostali korisnici neće moći vidjeti tvoje osobne podatke, samo tvoj nadimak</strong>.
                        </p>{' '}
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues}>
                            {({ values, handleChange, handleBlur }) => (
                                <Form noValidate className="profilePopup_form">
                                    {initialValues.first_name === '' && (
                                        <TextField
                                            p={20}
                                            type="text"
                                            name="first_name"
                                            label="Ime"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.first_name}
                                            className="profilePopup_textField"
                                            variant="standard"
                                        />
                                    )}
                                    {initialValues.last_name === '' && (
                                        <TextField
                                            type="text"
                                            name="last_name"
                                            label="Prezime"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.last_name}
                                            className="profilePopup_textField"
                                            variant="standard"
                                        />
                                    )}
                                    {!initialValues.birthday && (
                                        <Field
                                            label="Datum rođenja"
                                            onBlur={handleBlur}
                                            name="birthday"
                                            placeholder="dd/mm/yyyy"
                                            className="profilePopup_textField"
                                            maxdate={subYears(new Date(), 13)}
                                            component={DatePickerField}
                                            variant="standard"
                                        />
                                    )}
                                    {initialValues.sex === '' && (
                                        <TextField
                                            name="sex"
                                            label="Spol"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.sex}
                                            className="profilePopup_textField"
                                            variant="standard"
                                            select>
                                            <MenuItem value="" disabled>
                                                Odaberi
                                            </MenuItem>
                                            <MenuItem value="female">Ženski</MenuItem>
                                            <MenuItem value="male">Muški</MenuItem>
                                        </TextField>
                                    )}
                                    {initialValues.city === '' && (
                                        <TextField
                                            type="text"
                                            name="city"
                                            label="Grad"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address}
                                            className="profilePopup_textField"
                                            variant="standard"
                                        />
                                    )}
                                    <ButtonsRow className="profilePopup_buttons">
                                        <Button type="submit" title="Ažuriraj" variant={buttonVariants.BETA}>
                                            Spremi
                                        </Button>
                                    </ButtonsRow>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
            </ProfilePopupStyled>
        </Modal>
    )
}

ProfilePopup.propTypes = {
    className: PropTypes.string,
    profileModalUid: PropTypes.string
}

ProfilePopup.defaultProps = {
    className: undefined,
    profileModalUid: undefined
}

export default ProfilePopup
